import { api } from './index';
import axios from 'axios';
import { convertKeyToTitle, formatDate, convertToEventOptions } from '../utils';

const resource = '/events';
const eventsByCameraResource = '/events-by-camera';

const getAllEvents = async (camera, scenario, startDate, endDate, tag, page, perPage = 10) => {
  const time = null;
  try {
    scenario = scenario != null ? scenario.toLowerCase() : scenario;
    camera = camera != null ? camera.toLowerCase() : camera;
    let response = await api.get(
      `${resource}?camera=${camera}&scenario_name=${scenario}&time=${time}&startDate=${startDate}&endDate=${endDate}&tag=${tag}&page=${page}&per_page=${perPage}`
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch events' });
      return {};
    }
    response = response.data;
    return {
      data: response.data,
      totalEvents: response.totalEvents,
      totalPages: response.totalPages,
      hasNext: response.hasNext,
      timezone: response.timezone
    };
  } catch (error) {
    console.log({ error });
    return {};
  }
};

const eventsByCamera = async (cameraName, scenarios, page = 1, perPage = 20) => {
  try {
    // if (scenarios == null || scenarios == undefined) {
    //   scenarios = null
    // }
    let response = await api.get(
      `${eventsByCameraResource}?camera=${cameraName}&scenario_name=${null}&page=${page}&per_page=${perPage}`
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch events' });
      return {};
    }
    response = response.data;

    return {
      data: response.data,
      totalEvents: response.totalEvents,
      totalPages: response.totalPages,
      hasNext: response.hasNext,
      timezone: response.timezone,
      scenarios: response.scenarios
    };
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const eventsByCameraId = async (cameraId, scenario, page = 1, perPage = 20) => {
  try {
    let response = await api.get(
      `/events-by-camera-id?id=${cameraId}&scenario_name=${scenario}&page=${page}&per_page=${perPage}`
    );
    if (!response.data) {
      console.log({ error: 'unable to fetch events' });
      throw new Error('Unable to fetch events');
    }
    response = response.data;

    return {
      data: response.data,
      totalEvents: response.totalEvents,
      totalPages: response.totalPages,
      hasNext: response.hasNext,
      timezone: response.timezone,
      scenarios: response.scenarios
    };
  } catch (error) {
    console.log({ error });
    throw new Error(error.message);
  }
};

const fetchEventNames = async () => {
  const response = await axios.get(
    'https://docsvisionify.blob.core.windows.net/docs-images/scenarios.json'
  );
  const { scenarios } = response.data;
  const events = convertToEventOptions(scenarios);
  return events;
};

const fetchCameraAndScenarioNames = async (dashboard = false) => {
  try {
    let response = await api.get(
      `${resource}?camera=${'All'}&scenario_name=${'All'}&time=null&startDate=${formatDate(
        new Date()
      )}&endDate=${formatDate(new Date())}&page=1&per_page=10`
    );
    response = response.data;
    const cameras = response.cameras || [];
    const areas = response.areas || [];
    const scenarios = response.scenarios || [];
    const newScenarios = scenarios.map((scenario) => convertKeyToTitle(scenario));
    const scenarioKeyMap = {};
    scenarios.forEach((scenario) => {
      scenarioKeyMap[convertKeyToTitle(scenario)] = scenario;
    });

    scenarioKeyMap['All'] = 'All';
    if (dashboard) {
      const filteredScenarios = newScenarios.filter((scenario) => {
        if (scenario === convertKeyToTitle('cycle-time')) return false;
        if (scenario === convertKeyToTitle('heatmap-and-pathtracks')) return false;
        if (scenario === convertKeyToTitle('station-occupancy')) return false;
        return true;
      });
      return [
        ['All', ...cameras],
        ['All', ...filteredScenarios],
        scenarioKeyMap,
        ['All', ...areas]
      ];
    }
    return [['All', ...cameras], ['All', ...newScenarios], scenarioKeyMap, ['All', ...areas]];
  } catch (e) {
    console.log({ e });
    return [];
  }
};

const getEventById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    if (!response.data) {
      console.log({ error: 'unable to fetch event' });
      throw new Error('Unable to fetch event');
    }
    const event = response.data || {};
    // event['event_names'] = JSON.parse(event['event_names'].replace(/'/g, '"'));
    event['event_names'] = event.event_names.split(',');

    return event;
  } catch (error) {
    throw new Error(error);
  }
};

const updateEventById = async (id, data) => {
  try {
    let ret = {};
    const response = await api.put(`${resource}/${id}`, data);
    if (!response.data) {
      throw new Error('Unable to update event');
    }
    const event = response.data || {};
    // event['event_names'] = JSON.parse(event['event_names'].replace(/'/g, '"'));
    event['event_names'] = event.event_names.split(',');
    ret = { event };
    if ('severity' in data) {
      ret.message = `Event marked as ${data.severity} severity`;
    } else if ('is_accurate' in data) {
      ret.message = `Event marked as ${data.is_accurate ? 'accurate' : 'inaccurate'}`;
    }
    return ret;
  } catch (error) {
    console.log({ error });
    throw new Error(error);
  }
};

const addTagToEvent = async (id, tag) => {
  try {
    const response = await api.post(`${resource}/${id}/tags/add`, { tag });
    if (!response.data) {
      console.log({ error: 'unable to add tag to event' });
      return {};
    }
    return response.data;
  } catch (error) {
    console.log({ error });
    throw new Error(error);
  }
};

const removeTagFromEvent = async (id, tag) => {
  try {
    const response = await api.post(`${resource}/${id}/tags/remove`, { tag });
    if (!response.data) {
      console.log({ error: 'unable to remove tag from event' });
      return {};
    }
    return response.data;
  } catch (error) {
    console.log({ error });
    throw new Error(error);
  }
};

export const fetchEventDropdownData = async () => {
  try {
    // fetch cameras, scenarios, tags
    const url = `${resource}?camera=${'All'}&scenario_name=${'All'}&time=null&startDate=${formatDate(
      new Date()
    )}&endDate=${formatDate(new Date())}&page=1&per_page=10`;
    let response = await api.get(url);
    response = response.data;
    // get scenarios and cameras from the same API
    let cameras = response.cameras || [];
    // let scenarios = response.scenarios || [];
    response = await axios.get(
      'https://docsvisionify.blob.core.windows.net/docs-images/scenarios.json'
    );
    let scenarios = response.data.scenarios || [];
    let availableScenarios = scenarios.filter((scenario) => scenario.completed);
    const scenariosWithCategories = {};
    availableScenarios.reduce((acc, scenario) => {
      if (scenario.category_name in acc) {
        acc[scenario.category_name] = [
          ...new Set([
            ...acc[scenario.category_name],
            ...Object.keys(scenario.events).map((event) => {
              return event;
            })
          ])
        ];
      } else {
        acc[scenario.category_name] = [
          ...new Set(
            Object.keys(scenario.events).map((event) => {
              return event;
            })
          )
        ];
      }
      return acc;
    }, scenariosWithCategories);

    scenarios = Object.keys(scenariosWithCategories).map((scenario) => {
      return {
        title: scenario,
        options: scenariosWithCategories[scenario].map((event) => {
          return {
            value: event,
            label: event
              .replace(/_/g, ' ')
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          };
        })
      };
    });

    response = await api.get('/tags');
    response = response.data;
    if (!response.success) {
      throw new Error('Unable to fetch tags');
    }
    const tagTitleNameMapping = { All: 'All' };
    let tags = response.data;
    let tagsList = tags.map((tag) => tag.title);
    tags.reduce((acc, tag) => {
      acc[tag.title] = tag.name;
      return acc;
    }, tagTitleNameMapping);

    return {
      cameras: ['All', ...cameras],
      scenarios: [
        {
          title: 'All',
          options: [{ value: 'All', label: 'All' }]
        },
        ...scenarios
      ],
      tags: ['All', ...tagsList],
      tagTitleNameMapping
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchEventsByDate = async (payload) => {
  try {
    const response = await api.post('/events/fetchByDate', payload);
    if (!response.data) {
      throw new Error('Unable to fetch events');
    }
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEventCountByCameraId = async ({ id, payload }) => {
  try {
    const response = await api.post(`/event/count/${id}`, { ...payload });
    if (!response.data) {
      throw new Error('Unable to fetch events');
    }
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEventDetailsById = async (id) => {

  try {
    const response = await api.get(`/event-details/${id}`)
    if (!response.data) {
      throw new Error('Unable to fetch event details');
    }
    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

export {
  removeTagFromEvent,
  addTagToEvent,
  getAllEvents,
  getEventById,
  updateEventById,
  fetchCameraAndScenarioNames,
  fetchEventNames,
  eventsByCamera
};
