import { api } from './index';
const resource = '/views';

export const getAllCameraViews = async (currentPage = 1, pageSize = 10) => {
  try {
    const response = await api.get(`${resource}/`, {
      params: {
        currentPage: currentPage,
        pageSize: pageSize
      }
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const createCameraView = async (payload) => {
  try {
    console.log('payload', payload);
    const response = await api.post(`${resource}/create`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getCameraViewById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const editCameraView = async (id, payload) => {
  try {
    const response = await api.put(`${resource}/edit/${id}`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteCameraView = async (id) => {
  try {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
