import React, { useEffect, useState, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FullScreen } from 'react-full-screen';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { formatTimeFromSeconds } from '../../utils';
import { Icons } from '../../Icons';
import { format } from 'date-fns';

export const StatCard = ({ title, trend, trendNumber, value, inverseTrendBenefit = false }) => {
  const isValueNA = value === null || value === undefined;
  const isTrendNA = trendNumber === null || trendNumber === undefined;

  if (title === 'Time Since Last Incident') {
    return (
      <div className="h-screen w-screen flex items-center justify-center ">
        <div className="bg-white border shadow-md rounded-lg text-center">
          <div className="flex flex-col items-center justify-between m-[246px]">
            <h6 className="text-6xl font-semibold pb-[56px]">{title}</h6>
            <span className="text-9xl">{isValueNA ? 'N/A' : value}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="bg-white border shadow-md flex flex-col justify-between rounded-lg">
        <div className="flex flex-col items-center justify-between m-[246px]">
          <h6 className="text-6xl pb-[56px] font-semibold">{title}</h6>
          <div className="flex items-center justify-between">
            <div className="flex flex-col  justify-between">
              <span className="text-9xl">{isValueNA ? 'N/A' : value}</span>

              {!isValueNA && !isTrendNA && trend === 'POSITIVE' && (
                <div className="flex pt-[16px]">
                  <span
                    className={`text-[${!inverseTrendBenefit ? '#45A637' : '#E51C00'}] text-4xl mr-[8px]`}>
                    +{Number(trendNumber).toFixed(2)}%
                  </span>
                  <Icons.upwardArrow
                    className="h-[48px]"
                    fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
                  />
                </div>
              )}

              {!isValueNA && !isTrendNA && trend === 'NEGATIVE' && (
                <div className="flex">
                  <span
                    className={`text-[${!inverseTrendBenefit ? '#E51C01' : '#45A637'}] text-4xl mr-[8px]`}>
                    -{Number(trendNumber).toFixed(2)}%
                  </span>
                  <Icons.downwardArrow
                    className="h-[48px]"
                    fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
                  />
                </div>
              )}
            </div>

            {!isValueNA && !isTrendNA && trend === 'POSITIVE' && (
              <div className="pl-[64px]">
                <Icons.upwardLineChart
                  className="w-[256px] h-[256px]"
                  stroke={`${!inverseTrendBenefit ? '#04CE00' : '#FF718B'}`}
                />
              </div>
            )}

            {!isValueNA && !isTrendNA && trend === 'NEGATIVE' && (
              <div className="pl-[64px]">
                <Icons.downwardLineChart
                  className="w-[256px] h-[256px]"
                  stroke={`${!inverseTrendBenefit ? '#FF718B' : '#04CE00'}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PlayView = ({ cameraViewData, setShowPlayView, showPlayView, fullScreenHandle }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [nextSlide, setNextSlide] = useState(0);
  const sliderRef = useRef(null);
  const [timeSince, setTimeSince] = useState('0d 0h 0m 0s');

  const eventVideos = cameraViewData?.event_details || [];
  const statData = cameraViewData?.metrics;

  // Automatically enter full screen when the play view is shown
  useEffect(() => {
    if (showPlayView && !fullScreenHandle.active && !isFullScreen) {
      fullScreenHandle.enter();
      setIsFullScreen(true);
    }
  }, [showPlayView, fullScreenHandle, isFullScreen]);

  // Handle escape from full screen
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
        setShowPlayView(false);
      }
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const formatTimeSince = (dateString) => {
    if (!dateString) {
      return '0d 0h 0m 0s';
    }
    const now = new Date();
    const date = new Date(dateString);
    const diff = now - date;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (cameraViewData?.metrics?.timeSinceLastIncident?.value) {
        setTimeSince(formatTimeSince(cameraViewData.metrics.timeSinceLastIncident.value));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cameraViewData]);

  // Move to the next slide
  const handleNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handleSlideChange = (oldIndex, newIndex) => {
    setNextSlide(newIndex);
    // if (newIndex === mixedContent.length - 1) {
    //   setTimeout(() => {
    //     sliderRef.current.slickGoTo(0); // Go back to the first slide
    //   }, 3000);
    // }
  };

  // Slick Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: 'ondemand',
    beforeChange: handleSlideChange
  };

  // Combine stats and video slides
  const statSlides = [
    {
      type: 'stat',
      component: (
        <StatCard
          title={'Time Since Last Incident'}
          trend={'NEUTRAL'}
          trendNumber="0"
          value={timeSince}
        />
      )
    },
    {
      type: 'stat',
      component: (
        <StatCard
          title={'Time Between Incidents'}
          trend={statData?.averageTimeBetweenEvents?.trend ?? 'POSITIVE'}
          value={formatTimeFromSeconds(statData?.averageTimeBetweenEvents.value)}
          trendNumber={statData?.averageTimeBetweenEvents?.trendValue ?? 0}
        />
      )
    },
    {
      type: 'stat',
      component: (
        <StatCard
          title="Total Incidents"
          trend={statData?.totalIncidents?.trend ?? 'POSITIVE'}
          value={statData?.totalIncidents?.value ?? 0}
          trendNumber={statData?.totalIncidents?.trendValue ?? 0}
          inverseTrendBenefit={true}
        />
      )
    },
    {
      type: 'stat',
      component: (
        <StatCard
          title="Reviewed Incidents"
          trend={statData?.reviewedIncidents?.trend ?? 'POSITIVE'}
          value={
            `${statData?.reviewedIncidents?.value ?? 0} (${(statData?.reviewedIncidentsPercentage?.value ?? 0).toFixed(2)}%)` ??
            '0 (0%)'
          }
          trendNumber={statData?.reviewedIncidents?.trendValue ?? 0}
        />
      )
    }
  ];

  const videoSlides = eventVideos.map((videoItem) => {
    const formattedDate = format(new Date(videoItem.event_time), 'do MMM, yyyy - h:mm a');

    return {
      type: 'video',
      component: (
        <div
          key={videoItem.id}
          className="h-screen w-full flex flex-col justify-center items-center px-4 py-4">
          <div className="w-full flex justify-between items-center px-24 py-6 text-dark-tremor-content-strong">
            <h2 className="text-5xl font-bold text-left">{videoItem.camera_name}</h2>
            <p className="text-4xl text-right">
              {videoItem.event_names
                .split(',')
                .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
                .join(', ')}
            </p>
          </div>
          <div className="w-full">
            <ReactPlayer
              key={`${videoItem.id}-${nextSlide}`}
              url={videoItem.inference_video_path}
              controls
              playing
              onEnded={handleNextSlide}
              width="100%"
              height="80vh"
            />
          </div>
          <div className="mt-4 text-center text-4xl  text-dark-tremor-content-strong">
            <p>{formattedDate}</p>
          </div>
        </div>
      )
    };
  });

  // Interleave stat and video slides dynamically
  const mixedContent = [];
  const maxLength = Math.max(statSlides.length, videoSlides.length);

  for (let i = 0; i < maxLength; i++) {
    if (statSlides[i]) {
      mixedContent.push(statSlides[i]);
    }
    if (videoSlides[i]) {
      mixedContent.push(videoSlides[i]);
    }
  }

  useEffect(() => {
    if (mixedContent[nextSlide]?.type === 'stat') {
      const timer = setTimeout(() => {
        handleNextSlide();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [nextSlide]);

  // Move to the next or previous slide using arrow keys
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      sliderRef.current?.slickNext();
    } else if (e.key === 'ArrowLeft') {
      sliderRef.current?.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <FullScreen handle={fullScreenHandle}>
      {fullScreenHandle.active && (
        <Slider ref={sliderRef} {...sliderSettings}>
          {mixedContent.map((content, index) => (
            <div key={index}>
              {content.type === 'stat' && content.component}
              {content.type === 'video' && content.component}
            </div>
          ))}
        </Slider>
      )}
    </FullScreen>
  );
};
