import { api } from './index';
const resource = '/sites';

export const getSites = async () => {
    try {
        const response = await api.get(`${resource}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const getSiteInfo = async ({id, payload}) => {
    try {
        const response = await api.post(`${resource}/events/${id}`, {...payload})
        return response.data
    } catch (error) {
        throw new Error(error)
    }

}

export const createSite = async (data) => {
    try {
        const response = await api.post(`${resource}`, data)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const updateSite = async ({id, data}) => {
    try {
        const response = await api.put(`${resource}/${id}`, data)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}