import { api } from './index';
const resource = "/cameras"

export const getCameras = async () => {
    try {
        const response = await api.get(`/v2${resource}/`)
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getCamerasWithGraphData = async () => {
    try {
        const response = await api.get(`/v2${resource}/graph-data`)
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getCameraSnapshotByName = async (name) => {
    try {
        const response = await api.get(`${resource}/${name}/snapshot?buffer=true`)
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getCamerasForWhichScenarioIsNotApplied = async (scenarioTitle) => {
    try{
        const response = await api.get(`/v2${resource}/${scenarioTitle}/scenarios`)
        if(response.data?.success){
            return response.data.data
        }
        return {}
    }
    catch(e){
        throw new Error(e)
    }
}

export const addCamera = async (camera) => {
    try {
        const payload = {
            name: camera.cameraName, 
            camera_location: camera.area,
            campany_id: 1, 
            description: camera.description,
            motionTimeout: 15,
            thumbnail_url: camera.thumbnailUrl,
            trainingRecord: false,
            recordOnMovement: false,
            prebuffering: false,
            videoConfig: {
              source: '',
              stillImageSource: '',
              stimeout: 10,
              audio: false,
              debug: false,
            },
            mqtt: {},
            smtp: {
              email: camera.name,
            },
            videoanalysis: {
              active: false,
              regions: [
                {
                  finished: true,
                  coords: [
                    [0, 100],
                    [80, 4],
                    [100, 0],
                    [100, 100],
                  ],
                },
              ],
            },
            vBoxes: [],
        }
        payload.videoConfig.source = camera.cameraSource

        payload.videoConfig.subSource = payload.videoConfig.source;
        payload.videoConfig.stillImageSource = payload.videoConfig.source;
        payload.rtsp = payload.videoConfig.source;
        const response = await api.post(`/v2${resource}/`, payload)
        return response.data
    } catch (e) {
        const errorMessage = e.response?.data?.message || e.message
        const errorStatus = e.response?.status
        throw { message: errorMessage, status: errorStatus }
    }
}


export const deleteCamera = async (cameraName) => {
    try {
        const response = await api.delete(`/v2${resource}/${cameraName}`)
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const updateCamera = async (cameraName, updatedCameraPayload) => {
    try {
        const payload = {
            area: updatedCameraPayload.area,
            description: updatedCameraPayload.description,
        }
        const response = await api.patch(`/v2${resource}/${cameraName}`, payload)
        return response.data
    } catch (error) {
        console.log({error, e: error.message})
        throw new Error(error)
    }
}

export const getCameraById = async (cameraId) => {
    try {
        const response = await api.get(`/v2${resource}/${cameraId}`)
        if(response.data?.success){
            return response.data.data
        }
        return response.data
    } catch (e) {
        throw new Error(e)
    }
}

export const getCameraStats = async (cameraId, timePeriod) => {
    try {
        const response = await api.get(`/v2/camera-stats/${cameraId}?timePeriod=${timePeriod}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const toggleCameraRecording = async (cameraId) => {
    try {
        const response = await api.get(`/v2/toggle-camera-recording/${cameraId}`)
        return response.data
    } catch (error) {
        throw new Error(error)
    }
}