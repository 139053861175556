import { Button, Spinner } from '@shopify/polaris';
import { useMutation, useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCameras } from '../../api/cameras.api';
import { getSiteInfo, updateSite } from '../../api/sites.api';
import KonvaDraggableCanvas from '../../components/DraggableKonvaCanvas';
import { ReadOnlyKonvaCanvas } from '../../components/ReadOnlyKonvaCanvas';

export default function Sitemap({ site, selectedDates, selectedFilters }) {
  const [api, contextHolder] = notification.useNotification();
  const { chosenSite } = useSelector((state) => state.auth);
  const [cameraIcons, setCameraIcons] = useState([]);
  const [initialCameraState, setInitialCameraState] = useState([]);

  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['siteMapCameras'],
    queryFn: getCameras
  });

  const { mutate: modifySiteMap, isPending } = useMutation({
    mutationFn: updateSite,
    mutationKey: ['updateSiteMap'],
    onSuccess: () => {
      setOpen(false);
      refetchSites();
      api.success({
        message: 'Site Map Configured Successfully!',
        placement: 'topRight'
      });
    },
    onError: () => {
      api.error({
        message: 'Something went wrong! Please try again later.',
        placement: 'topRight'
      });
    }
  });

  const {
    data: siteInfo,
    isLoading: areSitesLoading,
    isError: didSitesError,
    refetch: refetchSites
  } = useQuery({
    queryKey: ['siteMapSites', selectedFilters.scenario, selectedFilters.timeRange, selectedDates],
    queryFn: () =>
      getSiteInfo({
        id: chosenSite.id,
        payload: {
          scenario: selectedFilters.scenario,
          timeRange: selectedFilters.timeRange,
          startDate: selectedDates.start,
          endDate: selectedDates.end
        }
      })
  });
  useEffect(() => {
    if (siteInfo && !areSitesLoading && !didSitesError) {
      // dispatch(setSites(sites));

      const requiredSite = siteInfo;

      if (requiredSite.sitemap_config) {
        setCameraIcons(
          Object.keys(requiredSite.eventCount).map((key) => requiredSite.eventCount[key])
        );
        setInitialCameraState(
          Object.keys(requiredSite.eventCount).map((key) => requiredSite.eventCount[key])
        );
      }
    } else if (didSitesError) {
      // dispatch(logout());
    }
  }, [siteInfo, areSitesLoading, didSitesError]);

  const handleCancel = () => {
    setOpen(false);
    setCameraIcons([...initialCameraState]);
    refetchSites();
  };

  return (
    <div className="mb-4 bg-white px-4 rounded-lg shadow-xl">
      {contextHolder}
      <div className="flex items-center justify-between text-xl">
        <div className="flex justify-between flex-wrap items-center lg:space-y-0 space-y-5">
          <h3 className="font-semibold text-[20px]">Site Map - {chosenSite.name}</h3>
        </div>
        {!isLoading && data && (
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="secondary"
            size="large">
            Edit
          </Button>
        )}
      </div>
      <div className="w-full flex justify-center">
        <ReadOnlyKonvaCanvas
          selectedDates={selectedDates}
          selectedFilters={selectedFilters}
          backgroundImageUrl={site.sitemap_url}
          canvasSize={{ width: 850, height: 500 }}
          initialIcons={cameraIcons}
        />
      </div>
      <Modal
        open={open}
        width={1000}
        title="Configure your site map"
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <span className="mr-1" key="back">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </span>,
          <span className="ml-1" key="submit">
            <Button
              onClick={() => {
                modifySiteMap({
                  id: chosenSite.id,
                  data: {
                    sitemap_config: { ...cameraIcons }
                  }
                });
              }}
              disabled={isPending}
              variant="primary">
              <div className="flex items-center">
                <span>Submit</span>
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </span>
        ]}>
        <KonvaDraggableCanvas
          selectedDates={selectedDates}
          selectedFilters={selectedFilters}
          icons={cameraIcons}
          setIcons={setCameraIcons}
          backgroundImageUrl={site.sitemap_url}
          cameras={data?.data?.map((cam) => ({
            label: cam.name,
            value: JSON.stringify({
              id: cam.id,
              name: cam.name
            })
          }))}
        />
      </Modal>
    </div>
  );
}
