/* eslint-disable no-unused-vars */
import '@shopify/polaris/build/esm/styles.css';
import '@shopify/polaris-viz/build/esm/styles.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppProvider, Spinner } from '@shopify/polaris';
import { PolarisVizProvider } from '@shopify/polaris-viz';

import en from '@shopify/polaris/locales/en.json';

import Dashboard from './containers/Dashboard/index';
import NotFound from './containers/NotFound/index';
import ScenarioListing from './containers/ScenarioListing';
import ApplyScenarios from './containers/ApplyScenarios';

import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScenarioDetails from './containers/ScenarioDetails';
import CamerasList from './containers/Cameras/index';
import CameraDetails from './containers/CameraDetails/index';
import SetPassword from './containers/SetPassword';
import Settings from './containers/Settings/index';
import Events from './containers/Events/index';
import Login from './containers/Login/index';
import CompanySettings from './containers/CompanySettings/index';
import Speaker from './containers/Speakers/Index';
import AddSpeaker from './containers/Speakers/AddSpeaker';
import EditSpeaker from './containers/Speakers/EditSpeaker';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';
import { PersistGate } from 'redux-persist/integration/react';
import EventDetails from './components/EventOverlay';
import Campaigns from './containers/Campaign';
import AddCampaign from './containers/Campaign/AddCampaign';
import ViewCampaign from './containers/Campaign/ViewCampaign';
import CameraView from './containers/CameraView/index';
import AddCameraView from './containers/CameraView/AddView';
import EditCameraView from './containers/CameraView/EditView';
import CameraViewStats from './containers/CameraView/ViewStats';

import Areas from './containers/Areas/index';
import AreaDetail from './containers/Areas/area-details';
import AreaConfig from './containers/Areas/area-config';
// import ErrorBoundary from './ErrorBoundary';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={<Spinner size="large" />} persistor={persistor}>
          {/* <ErrorBoundary> */}
            <AppProvider i18n={en}>
              <PolarisVizProvider>
                <Router>
                  <div className="main-content">
                    <Routes>
                      <Route element={<PrivateRoutes />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/events/:eventId" element={<EventDetails showOne={true}/>}/>
                        <Route path="/scenarios" element={<ScenarioListing />} />
                        <Route path="/scenarios/apply/:id/:name" element={<ApplyScenarios />} />
                        <Route
                          path="/scenarios/edit/:id/:name/:cameraId"
                          element={<ApplyScenarios edit={true} />}
                        />
                        <Route path="/scenarios/:name" element={<ScenarioDetails />} />
                        <Route path="/cameras" element={<CamerasList />} />
                        <Route path="/cameras/:id" element={<CameraDetails />} />

                        <Route path="/campaigns" element={<Campaigns />} />
                        <Route path="/campaigns/:id" element={<ViewCampaign />}/>
                        <Route path="/add-campaign" element={<AddCampaign />}/>
                        

                        <Route path="/settings" element={<Settings />} />
                        <Route path="/company-settings" element={<CompanySettings />} />
                        <Route path="/speakers" element={<Speaker />} />
                        <Route path="/speakers/create" element={<AddSpeaker />} />
                        <Route path="/speakers/:speakerId" element={<EditSpeaker />} />

                        <Route path="/views" element={<CameraView />} />
                        <Route path="/views/create" element={<AddCameraView />} />
                        <Route path="/views/edit/:cameraViewId" element={<EditCameraView />} />
                        <Route path="/views/play/:cameraViewId" element={<CameraViewStats />} />
                        
                        <Route path="/areas" element={<Areas />} />
                        <Route path="/areas-detail/:id" element={<AreaDetail />} />
                        {/* <Route path="/areas-config/apply/:id/:name" element={<AreaConfig />} /> */}
                        <Route path="/areas-config/apply/:id/:name" element={<ApplyScenarios />} />
                      </Route>
                      <Route element={<PublicRoutes />}>
                        <Route path="/" element={<Login />} />
                        <Route path="/configure-password" element={<SetPassword />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                </Router>
              </PolarisVizProvider>
            </AppProvider>
          {/* </ErrorBoundary> */}
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
